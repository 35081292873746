import React from "react";
import Seo from "../../components/seo";
import Industry from "../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../components/layout";
import {Retail} from '../../components/svgs/industries';

const RetailsIndustryPageFr = () => {
  const data = useStaticQuery(graphql`
    query RetailsIndustryDatasFr {
      allContentfulSecteurs(filter: {contentful_id: {eq: "7olBztWibd2z1GSgYNQwOl"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[1].node.permalink} 
    lang="fr-CA"
    title={data.allContentfulSecteurs.edges[0].node.name} 
    subTitle="L'affichage dynamique en"
    icon={<Retail/>}>
      <Seo 
        title="Diffusez du contenu de qualité sur autant d'écrans que vous le souhaitez."
        description="L'affichage dynamique capte l'attention de 78 % des gens exposés, voilà un excellent moyen de faire passer votre message!"
       />
      <Industry datas={data.allContentfulSecteurs.edges[0].node}/>
    </Layout>
  )
}

export default RetailsIndustryPageFr
